



import Vue from "vue";
const myWindow = require("@/../public/config");
export default Vue.extend({
  data() {
    return {
      titleName: "",
    };
  },
  created() {
    this.titleName = myWindow.configObj.VUE_APP_SYSTEM_TITLE;
  },
});
